
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/bootstrap-select/sass/bootstrap-select";



.active {
	a{
	color:white;
	}

}

h4.panel-title {
	
	color:green;
	font-weight:bold;


}

.form-group.required .control-label::after {
  content:"*";
  color:red;
}

